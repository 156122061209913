































































import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import CopyAlertComponent from '@/components/Management/molecules/CopyAlert.vue';
import CompanyTableHeaderComponent from '@/components/Management/molecules/CompanyTableHeader.vue';
import CompanySearchBoxComponent from '@/components/Management/molecules/CompanySearchBox.vue';
import TenantAddButtonComponent from '@/components/Management/molecules/TenantAddButton.vue';
import CompanyPlanSearchBoxComponent from '@/components/Management/molecules/CompanyPlanSearchBox.vue';
import { useCompanyList } from '@/composables/store/Management/useCompanyList';
import { useCompanyListProgressCircular } from '@/composables/store/Management/useCompanyListProgressCircular';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        CopyAlert: CopyAlertComponent,
        CompanyTableHeader: CompanyTableHeaderComponent,
        CompanySearchBox: CompanySearchBoxComponent,
        TenantAddButton: TenantAddButtonComponent,
        CompanyPlanSearchBox: CompanyPlanSearchBoxComponent,
    },
    setup() {
        const { companyList, copyFlag, copy } = useCompanyList();
        const { isCompanyListProgressing } = useCompanyListProgressCircular();

        return {
            copyFlag,
            companyList,
            isCompanyListProgressing,
            copy,
        };
    },
});
