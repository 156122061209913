



















import { defineComponent } from '@vue/composition-api';
import { useCompanyList } from '@/composables/store/Management/useCompanyList';

export default defineComponent({
    setup() {
        const { fetchList, searchName } = useCompanyList();

        return {
            fetchList,
            searchName,
        };
    },
});
