




import { defineComponent } from '@vue/composition-api';
import { useRouter } from '@/composables/function/Common/useRouter';
import { useTenantCreationForm } from '@/composables/store/Management/useTenantCreationForm';

export default defineComponent({
    props: {
        company: {
            type: Object as () => { id: number; name: string },
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();
        const { beforeSetCompany } = useTenantCreationForm();

        const addTenant = (): void => {
            beforeSetCompany(props.company);

            router.push({
                name: 'TenantCreation',
                params: { id: `${props.company.id}` },
            });
        };
        return {
            addTenant,
        };
    },
});
