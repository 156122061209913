







import { defineComponent, onMounted } from '@vue/composition-api';
import NonTenantCompanyListCardComponent from '@/components/Management/organisms/NonTenantCompanyListCard.vue';
import CompanyListCardComponent from '@/components/Management/organisms/CompanyListCard.vue';
import { useCompanyList } from '@/composables/store/Management/useCompanyList';

export default defineComponent({
    components: {
        NonTenantCompanyListCard: NonTenantCompanyListCardComponent,
        CompanyListCard: CompanyListCardComponent,
    },
    setup() {
        const { fetchList, initSearchParam } = useCompanyList();

        onMounted((): void => {
            initSearchParam();
            fetchList();
        });
    },
});
