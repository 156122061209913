

























import { defineComponent, onMounted } from '@vue/composition-api';
import { useCompanyPlanSearchBox } from '@/composables/store/Management/AnalysisPlan/useCompanyPlanSearchBox';
import { useCompanyList } from '@/composables/store/Management/useCompanyList';
import { useTime } from '@/composables/function/Common/useTime';

export default defineComponent({
    setup() {
        const { menuPlanList, fetchMenuPlanList } = useCompanyPlanSearchBox();
        const { searchPlan, fetchList } = useCompanyList();
        const { convertToHourFormatNumber } = useTime();

        onMounted(() => {
            fetchMenuPlanList();
        });

        return {
            menuPlanList,
            searchPlan,
            convertToHourFormatNumber,
            fetchList,
        };
    },
});
