








































import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import CompanyTableHeaderComponent from '@/components/Management/molecules/CompanyTableHeader.vue';
import TenantAddButtonComponent from '@/components/Management/molecules/TenantAddButton.vue';
import { useCompanyList } from '@/composables/store/Management/useCompanyList';
import { useCompanyListProgressCircular } from '@/composables/store/Management/useCompanyListProgressCircular';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        CompanyTableHeader: CompanyTableHeaderComponent,
        TenantAddButton: TenantAddButtonComponent,
    },
    setup() {
        const { dontHasTenantCompanyList } = useCompanyList();
        const { isNotCreatedCompanyListProgressing } = useCompanyListProgressCircular();

        return {
            dontHasTenantCompanyList,
            isNotCreatedCompanyListProgressing,
        };
    },
});
